* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

@keyframes blink {
    50% {
        fill: transparent;
    }
}
.dot {
    animation: 1s blink infinite;
    fill: grey;
}
.dot:nth-child(2) {
    animation-delay: 250ms;
}
.dot:nth-child(3) {
    animation-delay: 500ms;
}

::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar-thumb {
    background: #84ceff;
    border-radius: 13px;
}
::-webkit-scrollbar-track {
    background: #fff;
}

.runnigText {
    width: 0px;
    overflow: hidden;
    text-wrap: nowrap;
    animation: runnigText 1s ease-out 0s 1 forwards;
}

@keyframes runnigText {
    25% {
        width: 25%;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
}
